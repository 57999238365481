<template>
  <div>
    <v-btn class="mx-2" fab small @click="back()">
      <v-icon dark> mdi-arrow-left </v-icon>
    </v-btn>
    <div v-if="order != null">
      <div v-if="image == null">
        <!-- HEADER -->
        <div class="product-item-wrap emb-card mb-4">
          <div class="emb-card-content grey lighten-3 pa-4">
            <v-container>
              <v-row no-gutters>
                <v-col class="font-weight-black">Order id: </v-col>
                <v-col>
                  {{ order.id }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="font-weight-black">Receipt id: </v-col>
                <v-col>
                  <div v-if="order.receipt_id != undefined">
                    {{ order.receipt_id }}
                  </div>
                  <div v-if="order.stripe_payment_invoice != undefined">
                    {{ order.stripe_payment_invoice.replace("in_", "") }}
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="font-weight-black">Client name: </v-col>
                <v-col>
                  {{ order.client_firstname }} {{ order.client_lastname }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="font-weight-black">Client email: </v-col>
                <v-col>
                  {{ order.client_email }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="font-weight-black">Client id: </v-col>
                <v-col>
                  {{ order.client_id }}
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col class="font-weight-black">Assigned to: </v-col>
                <v-col>
                  {{ getDisplayNameFromUid(order.assigned_to) }}
                </v-col>
              </v-row>
              <v-row no-gutters v-if="order.added_by_admin != undefined">
                <v-col class="font-weight-black">Added by: </v-col>
                <v-col>
                  {{ getDisplayNameFromUid(order.added_by_admin) }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="font-weight-black">Status: </v-col>
                <v-col>
                  {{ order.status }}
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col class="font-weight-black">Order date: </v-col>
                <v-col>
                  {{ $formatDate(order.order_date) }}
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col class="font-weight-black">Total price: </v-col>
                <v-col>
                  {{ $formatMoney(order.total_amount, 2, "USD") }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="font-weight-black">Total price paid: </v-col>
                <v-col>
                  {{
                    $formatMoney(
                      order.total_amount_currency.amount,
                      2,
                      order.total_amount_currency.currency,
                      1
                    )
                  }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="font-weight-black">Credit: </v-col>
                <v-col>
                  <div v-if="order.order_type == 'INTERAC'">
                    {{ $formatMoney(order.credit, 2, "USD") }}
                  </div>
                  <div v-if="order.order_type == 'STRIPE'">
                    {{
                      $formatMoney(order.total_amount_currency.credit, 2, "USD")
                    }}
                  </div>
                </v-col>
              </v-row>
            </v-container>

            <!-- <div class="inline-block">
          <p>Frite</p>
          pomme
        </div>
        <div class="emb-meta-info layout align-center justify-space-between">
          <div class="inline-block">
            <h6 class="accent--text">
              123
              <emb-currency-sign></emb-currency-sign>
            </h6>
          </div>
        </div> -->
          </div>
        </div>

        <div
          :class="
            product.ready
              ? 'product-item-wrap emb-card my-2 light-green lighten-4'
              : 'product-item-wrap emb-card my-2'
          "
          v-for="(product, index) of order.products"
          :key="index"
        >
          <div class="d-flex align-start flex-column pa-4">

            <div v-if="order.status == 'ASSIGNED'">
              <v-btn
                fab
                x-small
                right
                absolute
                class="mr-4"
                @click="edit(product)"
                v-if="edit_product == null"
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
              <v-btn
                fab
                x-small
                right
                absolute
                class="mr-4"
                @click="save()"
                v-else-if="edit_product == product"
              >
                <v-icon> mdi-content-save </v-icon>
              </v-btn>
            </div>
            <div>
              <v-btn
                fab
                x-small
                right
                
                class="mr-4"
                @click="removeTicket(product)"
                v-if="edit_product == null && order.products.length > 1"
              >
                <v-icon> mdi-close</v-icon>
              </v-btn>
            </div>
            <v-container>
              <v-row no-gutters>
                <v-col>
                  <v-container>
                    <v-row no-gutters>
                      <v-col class="font-weight-black">Type: </v-col>
                      <v-col>
                        <div v-if="edit_product == product">
                          <v-select
                            :items="lottery_types"
                            item-text="text"
                            item-value="value"
                            v-model="edit_product_type_name"
                            solo
                            @click.native.stop
                          ></v-select>
                        </div>
                        <div v-else>
                          <v-img
                            :src="img_mapping[product.ticket.type_name]"
                            width="70"
                          />
                        </div>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col class="font-weight-black">Number of draw: </v-col>
                      <v-col>
                        <div v-if="edit_product == product">
                          <v-select
                            :items="multiplayOptions"
                            item-text="text"
                            item-value="value"
                            v-model="edit_product_multiplay"
                            solo
                            @click.native.stop
                          ></v-select>
                        </div>
                        <div v-else-if="product.ticket.multiplay > 1">
                          <b>{{ product.ticket.multiplay }}</b>
                        </div>
                        <div v-else>
                          {{ product.ticket.multiplay }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col class="font-weight-black">Draw Dates: </v-col>
                      <v-col>
                        <div v-if="product.scan != undefined">
                          <div
                            v-for="(item, index) in product.scan.draw_dates"
                            :key="index"
                          >
                            {{ item }}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col class="font-weight-black"
                        >{{
                          $t(
                            "message.productsPage.multiplier." +
                              product.ticket.type_name +
                              ".name"
                          )
                        }}:
                      </v-col>
                      <v-col>
                        <div v-if="edit_product == product">
                          <v-select
                            :items="multiplierOptions"
                            item-text="text"
                            item-value="value"
                            v-model="edit_product_multiplier"
                            solo
                            @click.native.stop
                          ></v-select>
                        </div>
                        <div v-else>
                          <div
                            class="font-weight-black"
                            v-if="product.ticket.multiplier"
                          >
                            <mark class="pa-2">YES</mark>
                          </div>
                          <div v-else>No</div>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row
                      no-gutters
                      v-if="
                        product.ticket.quickPicks != undefined &&
                        product.ticket.quickPicks != 0
                      "
                      class="yellow lighten-4"
                    >
                      <v-col class="font-weight-black">Quickpicks: </v-col>
                      <v-col>
                        <div v-if="edit_product == product">
                          <v-text-field
                            type="number"
                            v-model="edit_product_quickpicks"
                          >
                          </v-text-field>
                        </div>
                        <div v-else>
                          {{ product.ticket.quickPicks }}
                        </div>
                      </v-col>
                    </v-row>

                    <v-row
                      no-gutters
                      v-if="
                        product.ticket.quickPicks == undefined ||
                        product.ticket.quickPicks == 0
                      "
                    >
                      <v-col>
                        <v-expansion-panels flat>
                          <v-expansion-panel>
                            <v-expansion-panel-header
                              class="font-weight-black pa-0 yellow lighten-4"
                              >Grids ({{
                                product.ticket.grids.length
                              }})</v-expansion-panel-header
                            >
                            <v-expansion-panel-content class="yellow lighten-4">
                              <div
                                v-for="(grid, grid_index) of product.ticket
                                  .grids"
                                :key="grid_index"
                              >
                                <h5>
                                  {{ formatGrid(grid) }}
                                </h5>
                              </div>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                    </v-row>

                    <v-row
                      no-gutters
                      v-if="
                        product.scan != undefined &&
                        product.scan.grids != undefined
                      "
                    >
                      <v-col class="font-weight-black">Scanned: </v-col>
                      <v-col>
                        <div
                          v-for="(numbers_item, numbers_index) in product.scan
                            .grids"
                          :key="numbers_index"
                        >
                          {{ product.scan.grids[numbers_index].play }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        v-if="
                          order.status != 'COMPLETED' &&
                          order.status != 'CANCELED'
                        "
                      >
                        <router-link
                          :to="{
                            name: 'Camera',
                            params: {
                              order: order,
                              product_index: index,
                              acceptCallback: updateImage,
                              type: 'user',
                            },
                          }"
                        >
                          <v-btn class="mx-2" fab dark x-small>
                            <v-icon dark> mdi-camera </v-icon>
                          </v-btn>
                        </router-link>
                      </v-col>
                      <v-col>
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          x-small
                          @click="viewImage(product.scan.path)"
                          v-if="
                            product.scan != undefined &&
                            product.scan.image != undefined
                          "
                        >
                          <v-icon dark> mdi-image </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>

                <!-- <v-col cols="1">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    x-small
                    :color="
                      product.ticket.checked == undefined ||
                      product.ticket.checked == false
                        ? 'grey'
                        : 'green'
                    "
                    @click="checkTicket(index)"
                  >
                    <v-icon dark> mdi-check </v-icon>
                  </v-btn>
                </v-col> -->
              </v-row>
              <!-- <v-row>
                <v-expansion-panels accordion v-if="product.scan != undefined">
                  <v-expansion-panel>
                    <v-expansion-panel-header>Scan</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <scan-result :order="order" :index="index"></scan-result>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row> -->
            </v-container>
          </div>
        </div>
        <div
          class="d-flex justify-center ma-4"
          v-if="order.status == 'ASSIGNED'"
        >
          <v-btn
            tile
            color="success"
            @click="promoteCompleted()"
            :disabled="!order_ready"
          >
            Promote to Completed
            <v-icon right> mdi-arrow-right </v-icon>
          </v-btn>
        </div>
        <div
          class="d-flex justify-center"
          v-if="order.status == 'ASSIGNED' || order.status == 'CANCELED'"
        >
          <v-btn tile color="red lighten-3" @click="demoteToSold()">
            <v-icon right> mdi-arrow-left </v-icon>
            Demote to Sold
          </v-btn>
        </div>
        <div class="d-flex justify-center" v-if="order.status == 'COMPLETED'">
          <v-btn
            tile
            color="red lighten-3"
            @click="demoteToAssigned()"
            :disabled="is_demoting"
          >
            <div v-if="!is_demoting">
              <v-icon right> mdi-arrow-left </v-icon>
              Demote to Assigned
            </div>
            <div v-else>
              <v-progress-circular
                indeterminate
                color="red"
                size="20"
              ></v-progress-circular>
            </div>
          </v-btn>
        </div>
        <v-alert type="error" v-if="allCompletedError != ''"
          >{{ this.allCompletedError }}
        </v-alert>
      </div>
      <div v-else>
        <v-btn elevation="2" icon @click="closeImage()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-img :src="image" />
      </div>
    </div>
    <div v-if="order == null">Loading</div>
    <emb-delete-confirmation
      ref="demoteConfirmationDialog"
      message="Are you sure you want change status to Sold?"
      @onConfirm="onDemoteToSold"
    >
    </emb-delete-confirmation>
    <emb-delete-confirmation
      ref="removeTicketConfirmationDialog"
      message="Remove this ticket?"
      @onConfirm="onRemoveTicket"
    >
    </emb-delete-confirmation>
    <v-snackbar v-model="snackbar" color="red" timeout="8000">
      {{ error_msg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// import ScanResult from "../../components/Widgets/ScanResult.vue";
import { mapGetters } from "vuex";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";

export default {
  props: ["id"],
  computed: {
    ...mapGetters(["getDisplayNameFromUid"]),
  },
  data() {
    return {
      is_demoting: false,
      order_ready: false,
      selectedItem: null,
      order: null,
      image: null,
      // noImgDialog: false,
      allCompletedError: "",
      img_mapping: {
        megamillions: "/static/images/megamillions_mini.png",
        powerball: "/static/images/powerball_mini.png",
      },
      snackbar: false,
      error_msg: "",
      edit_mode: false,
      edit_product: null,
      edit_product_type_name: null,
      edit_product_multiplay: null,
      edit_product_multiplier: null,
      edit_product_quickpicks: null,
      remove_product: null,
      lottery_types: [
        {
          text: "Megamillions",
          value: "megamillions",
        },
        {
          text: "Powerball",
          value: "powerball",
        },
      ],
      multiplayOptions: [
        { text: "Single Draw", value: 1 },
        { text: "5 Draws", value: 5 },
        { text: "10 Draws", value: 10 },
        { text: "15 Draws", value: 15 },
        { text: "25 Draws", value: 25 },
      ],
      multiplierOptions: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
    };
  },
  created() {
    this.$store.dispatch("listen_order", this.id).then((order) => {
      this.order = order;
      this.set_order_ready();
    });
  },
  mounted() {
    if (this.order != null) {
      this.set_order_ready();
    }
  },
  methods: {
    back() {
      this.$router.back();
    },
    deleteItem(data) {
      this.$refs.deleteConfirmationDialog.openDialog();
      this.selectedItem = data;
    },
    onDeleteItemFromProductsList() {
      this.$refs.deleteConfirmationDialog.close();
      this.$emit("deleteProduct", this.selectedItem);
    },
    formatGrid(grid) {
      var result = "";
      for (var pool_index in grid.pool) {
        result += "[";
        for (var num_index in grid.pool[pool_index].numbers) {
          if (num_index != 0) {
            result += "-";
          }
          var num = grid.pool[pool_index].numbers[num_index];
          if (num < 10) {
            result += "0";
          }
          result += num.toString();
        }
        result += "]  ";
      }
      return result;
    },
    checkTicket(index) {
      var oldValue =
        this.order.products[index].ticket["checked"] == undefined ||
        this.order.products[index].ticket["checked"] == false
          ? false
          : true;
      this.$set(this.order.products[index].ticket, "checked", !oldValue);
      this.$store.dispatch("update_ticket_checked", this.order);
    },
    updateImage(order, index, blob) {
      var currentDate = new Date();
      var image_path =
        "tickets/" +
        currentDate.getFullYear().toString() +
        "/" +
        (currentDate.getMonth() + 1).toString() +
        "/" +
        currentDate.getDate().toString() +
        "/" +
        order.id.toString() +
        "_" +
        index +
        ".jpg";

      var metaData = {
        order_id: order.id,
        product_index: index,
        client_id: order.client_id,
        scan_date: currentDate,
        type: "order",
      };
      this.$store.dispatch("upload_image_to_storage", {
        path: image_path,
        image: blob,
        customMetadata: metaData,
      });
      this.$store.commit("set_cache_order", {
        id: order.id,
        type: "img_" + index.toString(),
        object: blob,
      });
    },
    async viewImage(path) {
      const storageRef = ref(getStorage(), path);
      getDownloadURL(storageRef).then((url) => {
        this.image = url;
      });
    },
    closeImage() {
      this.image = undefined;
    },
    promoteCompleted() {
      var info = {
        update_values: {
          general_status: "COMPLETED",
          status: "COMPLETED",
          update_date: new Date(),
        },
        docId: this.order.id,
      };
      this.$store
        .dispatch("update_order", info)
        .then(() => {
          this.$gtag.event("promote_to_completed");
          this.order.status = info.update_values.status;
          this.$router.back();
        })
        .catch((error) => {
          alert(error);
        });
    },
    demoteToAssigned() {
      this.is_demoting = true;
      const demoteToAssignedFunc = httpsCallable(
        getFunctions(),
        "demoteToAssigned"
      );
      demoteToAssignedFunc({
        order_id: this.order.id,
      })
        .then(() => {
          this.is_demoting = false;
          this.$router.back();
        })
        .catch((error) => {
          this.is_demoting = false;
          console.log("Error: ", error);
        });
    },
    demoteToSold() {
      // If changing canceled to sold, make sur the customer have
      // enought credit if the credit was used for this order
      if (this.order.status == "CANCELED" && this.order.credit != 0) {
        const docRef = doc(
          getFirestore(),
          "users_private",
          this.order.client_id
        );
        getDoc(docRef).then((doc_private) => {
          if (doc_private.data().credit < this.order.credit) {
            this.error_msg =
              "Unable to change status to SOLD. This order has a credit and the customer do not have enough credit (" +
              doc_private.data().credit +
              " USD).";
            this.snackbar = true;
          } else {
            this.$refs.demoteConfirmationDialog.openDialog();
          }
        });
      } else {
        this.$refs.demoteConfirmationDialog.openDialog();
      }
    },
    onDemoteToSold() {
      this.$refs.demoteConfirmationDialog.close();
      this.$store.dispatch("update_order", {
        update_values: { status: "SOLD" },
        docId: this.order.id,
      });
      this.$router.back();
    },
    set_order_ready() {
      // Validates that all tickets has been scanned
      var order_read_count = 0;
      for (var product of this.order.products) {
        if (product.ready == true) {
          order_read_count++;
        }
      }
      this.order_ready = order_read_count == this.order.products.length;
    },
    edit(product) {
      // console.log("Product: ", product);
      this.edit_product = product;
      this.edit_product_type_name = product.ticket.type_name;
      this.edit_product_multiplay = product.ticket.multiplay;
      this.edit_product_multiplier = product.ticket.multiplier;
      if (product.ticket.quickPicks != undefined) {
        this.edit_product_quickpicks = product.ticket.quickPicks;
      }
    },
    onRemoveTicket() {
      var index = this.order.products.indexOf(this.remove_product);
      this.order.products.splice(index, 1);
      this.$store.dispatch("update_order", {
        update_values: { products: this.order.products },
        docId: this.order.id,
      });
      this.$refs.removeTicketConfirmationDialog.close();
    },
    removeTicket(product) {
      this.$refs.removeTicketConfirmationDialog.openDialog();
      this.remove_product = product;
    },    
    save() {
      this.edit_product.ticket.type_name = this.edit_product_type_name;
      this.edit_product.ticket.multiplay = this.edit_product_multiplay;
      this.edit_product.ticket.multiplier = this.edit_product_multiplier;
      if (this.edit_product.ticket.quickPicks != undefined) {
        this.edit_product.ticket.quickPicks = parseInt(
          this.edit_product_quickpicks
        );
      }

      const docRef = doc(getFirestore(), "orders", this.id);
      updateDoc(docRef, this.order)
        .then(() => {
          this.edit_product = null;
        })
        .catch((error) => {
          console.log("Error: ", error);
          this.edit_product = null;
        });
    },
  },
};
</script>
